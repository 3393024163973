import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {NextStepButton} from '../../StepsManager/Components/NextStepButton/NextStepButton';
import {StatesButtonStates} from 'wix-ui-tpa';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from './WithBillingData/WithBillingData';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {cashierWidgetEwalletPaymentMethod} from '../../../../../domain/utils/cashier.utils';
import {useFedopsLogger} from '@wix/yoshi-flow-editor';
import {FedopsInteractions} from '../../../constants';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle/BillingAddressTitle';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
  continueButton = 'PaymentStep.continue',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const InternalPaymentStep = ({index}: DynamicStepComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {paymentsApi, activePaymentId} = usePaymentsApi();

  const {
    checkoutStore: {checkout, setPaymentAndBillingDetails, updateCheckoutError},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const fedops = useFedopsLogger();
  const stepState = stepsList[index].state;

  const isEwalletPaymentMethod = activePaymentId ? cashierWidgetEwalletPaymentMethod.includes(activePaymentId) : false;
  const shouldShowBillingForm = !(isEwalletPaymentMethod ? checkout.hasShippableItems : false);

  const {isFormValid, initForm, getContactDetailsFormValues, getVatFormValues, getAddressFormValues} = useBillingData();

  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);
  const [buttonDisabledState, setButtonDisabledState] = useState(!areFormsLoaded);

  useEffect(() => {
    if (updateCheckoutError) {
      setButtonState(StatesButtonStates.IDLE);
      setButtonDisabledState(false);
    }
  }, [updateCheckoutError]);

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        setButtonState(StatesButtonStates.IDLE);
        setButtonDisabledState(!areFormsLoaded);
        initForm();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  useEffect(() => {
    setButtonDisabledState(!areFormsLoaded);
  }, [areFormsLoaded]);

  const validateAndSubmit = async () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);
    const isBillingFormValid = shouldShowBillingForm ? await isFormValid() : true;
    const setBillingSameAsShipping = !shouldShowBillingForm;
    fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
    const {isValid: isPaymentValid} = (await paymentsApi?.validate()) ?? /* istanbul ignore next */ {};
    fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);

    if (isBillingFormValid && isPaymentValid) {
      const contactDetails = getContactDetailsFormValues();
      const address = getAddressFormValues();
      const vatId = getVatFormValues();

      void setPaymentAndBillingDetails({
        contactDetails: {
          ...contactDetails,
          ...(vatId ? {vatId} : {}),
        },
        address,
        setBillingSameAsShipping,
        activePaymentId,
      });
    } else {
      setButtonDisabledState(false);
      setButtonState(StatesButtonStates.IDLE);
    }
  };

  return (
    <>
      <StepHeader index={index} dataHook={PaymentStepDataHook.header} label={localeKeys.checkout.payment()} />
      <PaymentWidgetWrapper stepState={stepState} />
      {stepState === StepState.COLLAPSED && shouldShowBillingForm && (
        <>
          <BillingAddressTitle />
          <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
        </>
      )}
      {stepState === StepState.OPEN && (
        <>
          {shouldShowBillingForm && <BillingDetails />}
          <NextStepButton
            onClick={() => void validateAndSubmit()}
            text={localeKeys.checkout.continue_button_label()}
            dataHook={PaymentStepDataHook.continueButton}
            disabled={buttonDisabledState}
            buttonState={buttonState}
          />
        </>
      )}
    </>
  );
};

const WrappedInternalPaymentStep = withBillingData(InternalPaymentStep);

export const PaymentStep = ({index}: StepImplementationProps) => {
  return <DynamicStep index={index!} dataHook={PaymentStepDataHook.root} component={WrappedInternalPaymentStep} />;
};
